<template>
    <div id="container-extra-charge">
        <h4>Ticket number: {{ bookingId }}</h4>
        <div class="mb-5" v-if="listExtraCharges != null">

            <b-row class="col-12 mt-2" v-if="listExtraCharges.length == 0">
                <div class="col-12">
                    <h4 style="color:red;text-align: center;">There is not extra charges</h4>
                </div>
            </b-row>
            <b-row class="col-12">
                <div class="col-12" v-bind:class="{ noRecords: listExtraCharges.length == 0 }">
                    <b-button v-b-modal.modal-extra-charges :variant="'success'" class="mb-2 mt-2" style="float: right;"
                        @click="showAddNewCharge()">
                        <span class="d-none d-sm-inline">Create new charge</span>
                        <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
                    </b-button>
                </div>
            </b-row>

            <b-row class="col-12">
                <table v-if="listExtraCharges.length > 0" small caption-top responsive style="font-size: small"
                    id="table" class="table b-table">
                    <thead head-variant="dark">
                        <tr>

                            <th>Description</th>
                            <th>Status</th>
                            <th>Amount</th>
                            <th></th>
                            <th>Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in listExtraCharges" :key="item.id">

                            <td> {{ item.description }}</td>
                            <td> {{ item.status == null ? "" : item.status.toUpperCase() }}</td>
                            <td> {{ item.amount }} </td>
                            <td> <a v-if="item.type_invoice == 'cc'"
                                    :href="'https://dashboard.stripe.com/payments/' + item.charge_id" target="_blank">
                                    View Stripe
                                    Payment</a>
                            </td>
                            <td>
                                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL"
                                    transition="scale-transition" :offset-y="true">
                                    <template #button-content>
                                        <feather-icon icon="MoreVerticalIcon" size="16"
                                            class="align-middle text-body" />
                                    </template>
                                    <template v-slot:activator="{ on, attrs }">
                                        <b-btn color="primary" v-bind="attrs" v-on="on" icon ripple>
                                        </b-btn>
                                    </template>

                                    <template v-if="item.status != null">
                                        <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                                            <b-list-group-item
                                                v-if="item.status.trim().toUpperCase() === 'PRE-AUTHORIZED' || item.type_invoice == 'il'
                                                || item.status.trim().toUpperCase() == 'PAID'"
                                                @click="showEditModalExtraCharge(item.id, item.description, item.amount)"
                                                class="font-weight-bold"
                                                style="border: none; padding: 0px; color: #7367f0">
                                                <feather-icon icon="CheckIcon" />
                                                Edit 
                                            </b-list-group-item>
                                        </b-list-group-item>
                                    </template>


                                    <template v-if="item.status != null">
                                        <b-list-group-item v-if="item.status.trim().toUpperCase() === 'PRE-AUTHORIZED'"
                                            style="padding: 0" class="urlPagina" :ripple="false">
                                            <b-list-group-item @click="captureExtraCharge(item.id)"
                                                class="font-weight-bold"
                                                style="border: none; padding: 0px; color: #7367f0">
                                                <feather-icon icon="CheckIcon" />
                                                Capture
                                            </b-list-group-item>
                                        </b-list-group-item>
                                    </template>

                                    <template v-if="item.status != null">
                                        <b-list-group-item v-if="item.status.trim().toUpperCase() === 'PRE-AUTHORIZED'"
                                            style="padding: 0" class="urlPagina" :ripple="false">
                                            <b-list-group-item @click="cancelExtraCharge(item.id)"
                                                class="font-weight-bold"
                                                style="border: none; padding: 0px; color: #7367f0">
                                                <feather-icon icon="CheckIcon" />
                                                Cancel
                                            </b-list-group-item>
                                        </b-list-group-item>
                                    </template>

                                    <b-list-group-item v-if="$hasPermission('delete-records')" @click="deleteExtraCharge(item.id)" style="padding: 0"
                                        class="urlPagina" :ripple="false">
                                        <b-list-group-item @click="deleteExtraCharge(item.id)" class="font-weight-bold"
                                            style="border: none; padding: 0px; color: #7367f0">
                                            <feather-icon icon="CheckIcon" />
                                            Delete
                                        </b-list-group-item>
                                    </b-list-group-item>


                                    <b-list-group-item v-if="isPaid(item) == false && item.type_invoice == 'il'"
                                        @click="changeStatusInvoiceLaterExtraCharge(item.id, 1)" style="padding: 0"
                                        class="urlPagina" :ripple="false">
                                        <b-list-group-item class="font-weight-bold"
                                            style="border: none; padding: 0px; color: #7367f0">
                                            <feather-icon icon="CheckIcon" />
                                            Set as paid
                                        </b-list-group-item>
                                    </b-list-group-item>

                                    <b-list-group-item v-if="isPaid(item) == true && item.type_invoice == 'il'"
                                        @click="changeStatusInvoiceLaterExtraCharge(item.id, 0)" style="padding: 0"
                                        class="urlPagina" :ripple="false">
                                        <b-list-group-item class="font-weight-bold"
                                            style="border: none; padding: 0px; color: #7367f0">
                                            <feather-icon icon="CheckIcon" />
                                            Set as not paid
                                        </b-list-group-item>
                                    </b-list-group-item>


                                </b-dropdown>

                                <!-- 
                                <button style="padding: 7px; width: 25%;"
                                    v-if="item.status.trim().toUpperCase() === 'PRE-AUTHORIZED' || item.type_invoice == 'il'"
                                    @click="showEditModalExtraCharge(item.id, item.description, item.amount)"
                                    type="button" class="btn btn-warning mr-1">Edit</button>

                                <button style="padding: 7px;width: 25%;"
                                    v-if="item.status.trim().toUpperCase() === 'PRE-AUTHORIZED'"
                                    @click="captureExtraCharge(item.id)" type="button"
                                    class="btn btn-success mr-1">Capture</button>

                                <button @click="cancelExtraCharge(item.id)" style="padding: 7px;width: 25%;"
                                    v-if="item.status.trim().toUpperCase() === 'PRE-AUTHORIZED'" type="button"
                                    class="btn btn-danger mr-1">Cancel</button>


                                <button @click="deleteExtraCharge(item.id)" style="padding: 7px;width: 25%;"
                                    v-if="item.type_invoice == 'il'" type="button"
                                    class="btn btn-danger mr-1">Delete</button>


 -->
                            </td>
                        </tr>
                    </tbody>

                </table>
            </b-row>

        </div>


        <b-modal id="modal-extra-charges" centered hide-footer size="md">
            <div class="d-block text-center">
                <h3> {{ currentTitleActionExtraCharge }}</h3>

            </div>
            <div>
                <b-form-group label="Description">
                    <b-form-input v-model="currentDescription" type="text" :maxlength="250" />
                </b-form-group>
                <b-form-group label="Amount">
                    <b-form-input v-model="currentAmount" type="number" :maxlength="250" />
                </b-form-group>
            </div>
            <b-row class="mt-3">

                <b-col>
                    <b-button block @click="$bvModal.hide('modal-extra-charges')">Close</b-button>
                </b-col>
                <b-col>

                    <b-button v-if="currenExtraChargeId == null" variant="success" block
                        @click="addNewExtraCharge(bookingId, currentAmount, currentDescription)">Save</b-button>
                    <b-button v-if="currenExtraChargeId != null" variant="success" block
                        @click="updateExtraCharge(currenExtraChargeId, currentAmount, currentDescription)">Save</b-button>

                </b-col>
            </b-row>
        </b-modal>

    </div>
</template>

<script>
import {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem
} from "bootstrap-vue";
import vSelect from "vue-select";
import { getMessageError } from "@core/utils/utils";

export default {
    name: "DetailsInfoPaymentBooking",
    components: {
        BButton,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BForm,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        BFormSelect,
        vSelect,
        BDropdown,
        BDropdownItem,
        BListGroup,
        BListGroupItem
    },
    props: {
        bookingId: null,
        showTitleTicketNumber: false
    },
    data() {
        return {
            listExtraCharges: null,
            currentAmount: null,
            currentDescription: "",
            currenExtraChargeId: null,
            currentTitleActionExtraCharge: "",
        };
    },
    methods: {
        async GetListExtraCharges(bookingId) {
            this.$swal({
                title: "Please wait, loading data...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            await this.$http
                .get(`admin/panel/booking/get_list_extra_charges/${bookingId}`)
                .then((response) => {
                    this.$swal.close();
                    this.listExtraCharges = response.data.data;
                })
                .catch((error) => {
                    let message = getMessageError(error);
                    console.log(message);
                    this.$swal({
                        title: message,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        async addNewExtraCharge(bookingId, amount, description) {
            this.$bvModal.hide("modal-extra-charges");
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });
            let formData = new FormData();
            formData.append("booking_id", bookingId);
            formData.append("amount", amount);
            formData.append("description", description);

            let res = await this.$http
                .post(`/admin/panel/booking/add_extra_charge`, formData)
                .then((res) => {
                    this.$swal.close();
                    return true;
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                    this.$bvModal.show("modal-extra-charges");
                });
            if (res == true) {
                await this.GetListExtraCharges(bookingId);
                this.$root.$emit('extracharge_was_updated')
            }

        },
        async updateExtraCharge(bookingExtraChargeId, amount, description) {
            this.$bvModal.hide("modal-extra-charges");
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            let formData = new FormData();
            formData.append("bookingExtraChargeId", bookingExtraChargeId);
            formData.append("description", description);
            formData.append("amount", amount);

            let res = await this.$http
                .post(`/admin/panel/booking/update_extra_charge`, formData)
                .then((res) => {
                    this.$swal.close();
                    return true;
                })
                .catch((error) => {
                    let messsageError = getMessageError(error);
                    if (messsageError == "Charge already refunded") {
                        let data = this.listExtraCharges.find(x => x.id == bookingExtraChargeId);
                        data.status = "REFUNDED";
                    }
                    this.$swal({
                        title: messsageError,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                    this.$bvModal.show("modal-extra-charges");
                });

            if (res == true) {
                await this.GetListExtraCharges(this.bookingId);
                this.$root.$emit('extracharge_was_updated', this.listExtraCharges)
            }
        },
        captureExtraCharge(bookingExtraChargeId) {
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            let formData = new FormData();
            formData.append("bookingExtraChargeId", bookingExtraChargeId);

            this.$http
                .post(`/admin/panel/booking/capture_extra_charge`, formData)
                .then((res) => {
                    this.$swal.close();
                    if (res.data.data == "paid") {
                        let info = this.listExtraCharges.find(x => x.id == bookingExtraChargeId);
                        info.status = "paid";
                        this.$root.$emit('extracharge_was_updated')
                    }
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        async cancelExtraCharge(bookingExtraChargeId) {
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            let formData = new FormData();
            formData.append("bookingExtraChargeId", bookingExtraChargeId);

            let res = await this.$http
                .post(`/admin/panel/booking/cancel_extra_charge_stripe`, formData)
                .then((res) => {
                    this.$swal.close();

                    let info = this.listExtraCharges.find(x => x.id == bookingExtraChargeId);
                    info.status = "cancelled";
                    return true;
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
            if (res == true) {
                this.$root.$emit('extracharge_was_updated', this.listExtraCharges)
            }
        },
        async deleteExtraCharge(bookingExtraChargeId) {
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            let formData = new FormData();
            formData.append("bookingExtraChargeId", bookingExtraChargeId);

            let res = await this.$http
                .post(`/admin/panel/booking/delete_extra_charge`, formData)
                .then((res) => {
                    this.$swal.close();

                    return true;
                })
                .catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
            if (res == true) {
                await this.GetListExtraCharges(this.bookingId);
                this.$root.$emit('extracharge_was_updated', this.listExtraCharges)
            }
        },
        showEditModalExtraCharge(extraChargeId, description, amount) {
            this.currenExtraChargeId = extraChargeId;
            this.currentDescription = description;
            this.currentAmount = amount;
            this.currentTitleActionExtraCharge = "Edit extra charge"
            this.$bvModal.show("modal-extra-charges");
        },
        showAddNewCharge() {
            this.currenExtraChargeId = null;
            this.currentDescription = null;
            this.currentAmount = null;
            this.currentTitleActionExtraCharge = "Add new extra charge"
        },
        changeStatusInvoiceLaterExtraCharge(id, status) {
            let finalStatus = "paid";

            if (status == 0) {
                finalStatus = "";
            }

            this.$swal({
                title: "Do you want change the status?",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                buttonsStyling: true,
            }).then((result) => {
                if (result.value === true) {
                    this.$swal({
                        title: "Please, wait...",
                        didOpen: () => {
                            this.$swal.showLoading();
                        },
                    });
                    let formData = new FormData();
                    formData.append("id", id);
                    formData.append("status", finalStatus);
                    this.$http
                        .post(`admin/panel/set_status_extra_charge_invoice_later`, formData)
                        .then((response) => {
                            console.log(555555)
                            this.GetListExtraCharges(this.bookingId);
                            this.$root.$emit('extracharge_was_updated');
                            this.$swal.close();
                        })
                        .catch((error) => {
                            let message = getMessageError(error);
                            this.$swal({
                                title: message,
                                icon: "error",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        });
                }
            });
        },
        isPaid(item) {
            let res = true;
            if (item.status == null) {
                res = false;
            }
            if (item.status == '') {
                res = false;
            }
            return res;

        }

    },
    mounted() {
        this.GetListExtraCharges(this.bookingId);
    },
};
</script>

<style lang="scss">
.noRecords {
    display: flex;
    justify-content: center;
}
</style>