<template>
  <div>
    <ListInvoices></ListInvoices>
  </div>
</template>

<script>
import ListInvoices from '@core/layouts/components/admin-components/ListInvoices.vue'

export default {
  components: {
    ListInvoices,
  },
  data() {
    return {}
  },
}
</script>

<style>

</style>
